import { storeToRefs } from "pinia";
import { useSidebarsStore } from "~/store/useSidebarsStore";

let sidebars;

const ensureSidebarsInitialized = () => {
  if (!sidebars) {
    sidebars = storeToRefs(useSidebarsStore()).sidebars;
  }
};

export const showUnauthHistorySidebar = () => {
  ensureSidebarsInitialized();
  Object.assign(sidebars.value.unauthHistory, { show: true });
};

export const showLinkAnalyticsSidebar = ({ linkId }) => {
  ensureSidebarsInitialized();
  Object.assign(sidebars.value.analyticsSidebar, { show: true, linkId });
};
